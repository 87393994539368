// Utility Function for Image Beacons to track interactions

$(document).ready(function() {

    beacon = function (opts) {
        // Make sure we have a base object for opts
        opts = opts || {};
        // Setup defaults for options
        opts.url = opts.url || null;
        opts.vars = opts.vars || {};
        opts.error = opts.error || function () {
            };
        opts.success = opts.success || function () {
            };

        // Split up vars object into an array
        var varsArray = [];
        for (var key in opts.vars) {
            varsArray.push(key + '=' + opts.vars[key]);
        }
        // Build query string
        var qString = varsArray.join('&');

        // Create a beacon if a url is provided
        if (opts.url) {
            // Create a brand NEW image object
            var beacon = new Image();
            // Attach the event handlers to the image object
            if (beacon.onerror) {
                beacon.onerror = opts.error;
            }
            if (beacon.onload) {
                beacon.onload = opts.success;
            }

            // Attach the src for the script call
            beacon.src = opts.url + '?' + qString;
        }
    },

     $(document).on('click','.count',function(){
         countAnnounceClick($(this).attr('data-slug'));
     });

    // Handler for drawing a rectangle on map
    function countAnnounceClick( slug ){
            beacon({
                url : "/announce/count/" + slug,
                vars : {}
            });
    }
});