var menu = {

    init: function () {

        $('.dropdown-button').dropdown({
                inDuration: 300,
                outDuration: 225,
                constrain_width: false, // Does not change width of dropdown to that of the activator
                hover: true, // Activate on hover
                gutter: 0, // Spacing from edge
                belowOrigin: true, // Displays dropdown below the button
                alignment: 'left' // Displays dropdown with edge aligned to the left of button
            }
        );


        $('.dropdown-button2').dropdown({
                inDuration: 300,
                outDuration: 225,
                constrain_width: false, // Does not change width of dropdown to that of the activator
                hover: true, // Activate on hover
                gutter: 0, // Spacing from edge
                belowOrigin: true, // Displays dropdown below the button
                alignment: 'left' // Displays dropdown with edge aligned to the left of button
            }
        );


        $(document).on('click','#show-side-chatbar',function(){
            menu.closeChatBar();
        });
    },
    closeChatBar: function(){
        var marginLeft = $('.chat-sidebar').css('margin-left');
        if(marginLeft=="0px") {
            $('.chat-sidebar').removeClass('notdisplay').addClass('display');
        }else{
            $('.chat-sidebar').removeClass('display').addClass('notdisplay');
        }
    }
}
/**
 * init js
 */
$(document).ready(function() {
    menu.init();
});
