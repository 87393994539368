var sound=null;
var global = {

    init: function(){
        // request permission on page load

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $("img.lazy-load").unveil(800, function() {
            $(this).load(function() {
                this.style.opacity = 1;
            });
        });

        if($("#first-popup").length==1)
        {
            //$('#first-popup').openModal();
            $('#first-popup').openModal({
                    dismissible: false, // Modal can be dismissed by clicking outside of the modal
                    opacity: .5, // Opacity of modal background
                    in_duration: 0, // Transition in duration
                    out_duration: 0, // Transition out duration
                    starting_top: '10%', // Starting top style attribute
                    ending_top: '10%', // Ending top style attribute
                    ready: function (modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
                        var url_request = "/app/update-view-modal";
                        $.get(url_request, function (data) {
                        });
                    }
                }
            );
        }




        if($(".credit-remaining").length>=1) {
            window.setTimeout(function () {
                global.loadRemainningCredit('/app/user/load-remaining-credit', '.credit-remaining>span');
            }, 200);
        }

        $('select.material-select').material_select();

        $('.tooltipped').tooltip({delay: 50});
        $('.modal-trigger').leanModal({top : "5%", closeButton: ".modal_close",overlay : 1});

        $('.button-collapse').sideNav({
                menuWidth: 260, // Default is 240
                edge: 'left', // Choose the horizontal origin
                closeOnClick: true // Closes side-nav on <a> clicks, useful for Angular/Meteor
            }
        );


        $('#chat-sidebar').sideNav({
                menuWidth: 300, // Default is 240
                edge: 'right', // Choose the horizontal origin
                closeOnClick: true // Closes side-nav on <a> clicks, useful for Angular/Meteor
            }
        );


        $(document).on('click', '#toast-container .toast', function() {
            $(this).fadeOut(function(){
                $(this).remove();
            });
        });





        if($("body").hasClass('front-page')) {
            $(document).euCookieLawPopup().init({
                cookiePolicyUrl: 'https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi',
                popupPosition: 'bottomleft',
                colorStyle: 'default',
                compactStyle: true,
                popupTitle: 'MySMS utilise les cookies',
                popupText: 'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. Si vous continuez à utiliser ce dernier, nous considérerons que vous acceptez l\'utilisation des cookies.',
                buttonContinueTitle: "Ok",
                buttonLearnmoreTitle: 'En savoir plus',
                buttonLearnmoreOpenInNewWindow: true,
                agreementExpiresInDays: 30,
                autoAcceptCookiePolicy: true,
                htmlMarkup: null
            });
        }else{

            if(typeof ion !="undefined") {
                ion.sound({
                    sounds: [
                        {name: "notif"}
                    ],

                    // main config
                    path: "/statics/sounds/",
                    preload: true,
                    multiplay: false,
                    volume: 0.5
                });
            }

        }


        /*if(Notification) {
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            }
            global.notifyMe();
        }*/





    },
    loadRemainningCredit: function(url, elmt){


        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            error: function (resultat, statut, erreur) {
                console.log(resultat);
            },
            success: function (reponse, statut) {

                if(reponse.success){

                    $(elmt).addClass('animated fadeIn').html(reponse.credit);

                }else{
                    if(typeof reponse.redirectTo !='undefined' && reponse.redirectTo){
                        window.show_msg_beforeunload = false;
                        window.top.location.href = reponse.redirectTo;
                    }
                }

            }
        });



    },
    playSoundNotification: function(token){

        sound = document.getElementById("play-audio-notif-" + token);
        if(sound!=null) {
            if(typeof ion !="undefined") {
                ion.sound.play("notif");
            }
            //sound.play();
        }
    },

    notifyMe: function() {
        if (!Notification) {
            alert('Desktop notifications not available in your browser. Try Chromium.');
            return;
        }

        if (Notification.permission !== "granted")
            Notification.requestPermission();
        else {
            var notification = new Notification('Notification title', {
                icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
                body: "Hey there! You've been notified!",
            });

            notification.onclick = function () {
                window.open("http://stackoverflow.com/a/13328397/1269037");
            };

        }

    }

}
/**
 * init js
 */
$(document).ready(function() {
    global.init();
});