$(document).ready(function() {


    $('#password').hideShowPassword({
        show: false,
        innerToggle: true,
        //triggerOnToggle: false,
        toggle: {
            className: 'my-toggle'
        },
        // Options specific to the 'shown' or 'hidden'
        // states of the input element.
        states: {
            shown: {
                className: 'hideShowPassword-shown',
                changeEvent: 'passwordShown',
                props: { type: 'text' },
                toggle: {
                    className: 'hideShowPassword-toggle-hide',
                    content: 'Cacher',
                    attr: {
                        'aria-pressed': 'true',
                        title: 'Cacher le mot de passe',
                    }
                }
            },
            hidden: {
                className: 'hideShowPassword-hidden',
                changeEvent: 'passwordHidden',
                props: { type: 'password' },
                toggle: {
                    className: 'hideShowPassword-toggle-show',
                    content: 'Montrer',
                    attr: {
                        'aria-pressed': 'false',
                        title: 'Montrer le mot de passe',
                    }
                }
            }
        }
    });


});